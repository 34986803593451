import { api, getHotReport } from 'api/report';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';
import { cloneDeep } from 'lodash-es';
import dateUtils from '@/utils/dateutils.js';
import qs from 'querystring';
import { myMixin } from '@/mixins/mixins.js';
import optionData from '@/assets/js/optionData';
import { filterObject } from 'utils/utils';
import { mapState, mapActions } from 'vuex';
import { getReportSubruleList } from 'api/product/subrule';
import { getPidsList } from 'api/product/rankSearch';
import { getPermissionAllListNew } from 'api/account/permission';

export default {
  mixins: [myMixin],
  components: {
    Pagination,
    GroupSelect,
  },
  data() {
    return {
      pageParam: {
        page: 1,
        pageSize: 20,
      },
      dataParam: null,
      offerChecked: false,
      affiliateChecked: true,
      sourceChecked: false,
      fullWidth: document.documentElement.clientWidth,
      countriesChecked: false,
      packageChecked: false,
      affSubsChecked: false,
      platformChecked: false,
      monthChecked: false,
      dateChecked: true,
      hourChecked: false,
      eventNameChecked: false,
      subRuleIdChecked: false,
      sourceManagersChecked: false,
      pidChecked: false,
      offerLinkTypeChecked: false,
      startToEndDate: [],
      listLoading: false,
      total: null,
      radioOptions: ['payout', 'clicks', 'conversions'],
      summariesMap: {},
      hotReportList: [],
      timezoneOption: optionData.timezoneOption,
      categoryOption: optionData.categoryOption,
      pidList: [],
      platformOption: [
        {
          label: 'ALL',
          value: '',
        },
        {
          label: 'ANDROID',
          value: 'ANDROID',
        },
        {
          label: 'IOS',
          value: 'IOS',
        },
      ],
      commonReport: {
        offerIds: null,
        affSubs: null,
        affiliateIds: null,
        columns: [],
        comparing: true,
        countries: null,
        devices: [],
        first_level: 0,
        fromDate: null,
        toDate: null,
        pkgs: null,
        platform: null,
        sorting: '',
        sourceIds: null,
        ruleIds: [],
      },
      historyDeductList: [],
      sourceIdArray: [],
      affiliateIdArray: [],
      subRule: [],
      loading: {
        search: false,
      },
    };
  },
  mounted() {
    //默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.startToEndDate = [];
    this.startToEndDate[0] = dateUtils.date2StrYMD(end);
    this.startToEndDate[1] = dateUtils.date2StrYMD(start);

    this.getAffiliateList();
    this.getSourceIdsList();
    this.managerList();
    this.subRuleList();
    this.getPids();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    listenChange() {
      const {
        affiliateChecked,
        sourceChecked,
        affSubsChecked,
        offerChecked,
        countriesChecked,
        eventNameChecked,
        platformChecked,
        packageChecked,
        subRuleIdChecked,
        sourceManagersChecked,
        pidChecked,
        offerLinkTypeChecked,
      } = this;
      return {
        affiliateChecked,
        sourceChecked,
        affSubsChecked,
        offerChecked,
        countriesChecked,
        eventNameChecked,
        platformChecked,
        packageChecked,
        subRuleIdChecked,
        sourceManagersChecked,
        pidChecked,
        offerLinkTypeChecked,
      };
    },
  },
  watch: {
    listenChange: function() {
      this.$nextTick(() => {
        this.$refs.tableRef.doLayout();
      });
    },
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateList']),
    ...mapActions('source', ['getSourceIdsList']),
    managerList() {
      getPermissionAllListNew().then((res) => {
        console.log(res);
        this.sourceManagers = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.pidList = res.result;
      });
    },
    searchListClick(curPage) {
      if (curPage) {
        this.pageParam.page = curPage;
      }
      let param = {
        ...this.commonReport,
        ...this.pageParam,
      };
      if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
        param.sourceIds = param.sourceIds.join(',');
      } else {
        delete param.sourceIds;
      }
      if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
        param.affiliateIds = param.affiliateIds.join(',');
      } else {
        delete param.affiliateIds;
      }

      if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
        param.columns.push('offer_id');
      } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
        const offerIdIndex = param.columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        param.columns.splice(offerIdIndex, 1);
      }

      if (this.eventNameChecked == true && param.columns.indexOf('event_name') == -1) {
        param.columns.push('event_name');
      } else if (this.eventNameChecked == false && param.columns.indexOf('event_name') !== -1) {
        const eventNameIndex = param.columns.findIndex((item) => {
          if (item == 'event_name') {
            return true;
          }
        });
        param.columns.splice(eventNameIndex, 1);
      }

      if (
        this.affiliateChecked == true &&
        param.columns.indexOf('affiliate_id') == -1 &&
        param.columns.indexOf('company') == -1
      ) {
        param.columns.push('affiliate_id');
        param.columns.push('company');
      } else if (
        this.affiliateChecked == false &&
        param.columns.indexOf('affiliate_id') !== -1 &&
        param.columns.indexOf('company') !== -1
      ) {
        const affiliateIndex = param.columns.findIndex((item) => {
          if (item == 'affiliate_id') {
            return true;
          }
        });
        param.columns.splice(affiliateIndex, 1);

        const companyIndex = param.columns.findIndex((item) => {
          if (item == 'company') {
            return true;
          }
        });
        param.columns.splice(companyIndex, 1);
      }

      if (
        this.sourceChecked == true &&
        param.columns.indexOf('source_id') == -1 &&
        param.columns.indexOf('source_name') == -1
      ) {
        param.columns.push('source_id');
        param.columns.push('source_name');
      } else if (
        this.sourceChecked == false &&
        param.columns.indexOf('source_id') !== -1 &&
        param.columns.indexOf('source_name') !== -1
      ) {
        const sourceIdIndex = param.columns.findIndex((item) => {
          if (item == 'source_id') {
            return true;
          }
        });
        param.columns.splice(sourceIdIndex, 1);

        const sourceNameIndex = param.columns.findIndex((item) => {
          if (item == 'source_name') {
            return true;
          }
        });
        param.columns.splice(sourceNameIndex, 1);
      }

      if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
        param.columns.push('country');
      } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
        const countryIndex = param.columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        param.columns.splice(countryIndex, 1);
      }
      if (this.packageChecked == true && param.columns.indexOf('prod') == -1) {
        param.columns.push('prod');
      } else if (this.packageChecked == false && param.columns.indexOf('prod') !== -1) {
        const prodIndex = param.columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        param.columns.splice(prodIndex, 1);
      }

      if (this.affSubsChecked == true && param.columns.indexOf('aff_sub') == -1) {
        param.columns.push('aff_sub');
      } else if (this.affSubsChecked == false && param.columns.indexOf('aff_sub') !== -1) {
        const affSubIndex = param.columns.findIndex((item) => {
          if (item == 'aff_sub') {
            return true;
          }
        });
        param.columns.splice(affSubIndex, 1);
      }

      if (this.platformChecked == true && param.columns.indexOf('platform') == -1) {
        param.columns.push('platform');
      } else if (this.platformChecked == false && param.columns.indexOf('platform') !== -1) {
        const platformIndex = param.columns.findIndex((item) => {
          if (item == 'platform') {
            return true;
          }
        });
        param.columns.splice(platformIndex, 1);
      }

      if (this.subRuleIdChecked == true && param.columns.indexOf('rule_id') == -1) {
        param.columns.push('rule_id');
      } else if (this.subRuleIdChecked == false && param.columns.indexOf('rule_id') !== -1) {
        const ruleIdIndex = param.columns.findIndex((item) => {
          if (item == 'rule_id') {
            return true;
          }
        });
        param.columns.splice(ruleIdIndex, 1);
      }
      if (this.sourceManagersChecked == true && param.columns.indexOf('source_manager') == -1) {
        param.columns.push('source_manager');
      } else if (
        this.sourceManagersChecked == false &&
        param.columns.indexOf('source_manager') !== -1
      ) {
        const sourceManagersIndex = param.columns.findIndex((item) => {
          if (item == 'source_manager') {
            return true;
          }
        });
        param.columns.splice(sourceManagersIndex, 1);
      }
      param.ruleIds = this.commonReport.ruleIds.toString();
      if (this.commonReport.sourceManagers.length > 0) {
        param.sourceManagers = this.commonReport.sourceManagers.toString();
      }
      if (this.pidChecked == true && param.columns.indexOf('pid') == -1) {
        param.columns.push('pid');
      } else if (this.pidChecked == false && param.columns.indexOf('pid') !== -1) {
        const pidIndex = param.columns.findIndex((item) => {
          if (item == 'pid') {
            return true;
          }
        });
        param.columns.splice(pidIndex, 1);
      }
      if (this.commonReport.pid?.length > 0) {
        param.pid = this.commonReport.pid.toString();
      }
      if (this.offerLinkTypeChecked == true && param.columns.indexOf('offer_type') == -1) {
        param.columns.push('offer_type');
      } else if (this.offerLinkTypeChecked == false && param.columns.indexOf('offer_type') !== -1) {
        const offerLinkTypeIndex = param.columns.findIndex((item) => {
          if (item == 'offer_type') {
            return true;
          }
        });
        param.columns.splice(offerLinkTypeIndex, 1);
      }
      if (this.commonReport.pids?.length > 0) {
        param.pids = this.commonReport.pids.toString();
      }
      if (this.commonReport.offerTypes?.length > 0) {
        param.offerTypes = this.commonReport.offerTypes.toString();
      }
      if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }
      this.pageParam.page = 1;

      const dataParam = cloneDeep(param);
      if (Array.isArray(dataParam.columns)) {
        dataParam.columns = dataParam.columns.join(',');
      }
      this.dataParam = dataParam;

      this.commonReportApi();
    },
    commonReportApi() {
      this.listLoading = true;
      this.loading.search = true;
      let param = {
        ...this.dataParam,
        ...this.pageParam,
      };
      param = filterObject(param);
      console.log(param);
      getHotReport(param)
        .then((response) => {
          this.listLoading = false;
          this.loading.search = false;
          if (response.code === 200) {
            const result = response.result || {};
            console.log(result?.dataList, 'result');
            this.hotReportList = result?.dataList?.map((item) => {
              if (item.affiliateTrafficTag) {
                const arr = item.affiliateTrafficTag.split('-');

                item.affColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';

                item.affiliateTrafficTag = arr[1];
              }
              if (item.affSubTrafficTag) {
                const arr = item.affSubTrafficTag.split('-');
                item.affSubColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';
                item.affSubTrafficTag = arr[1];
              }
              return item;
            });
            console.log('hotReportList', this.hotReportList);
            this.summariesMap = result?.total;
            this.total = result?.total?.count;
            if (this.summariesMap) {
              const totalObject = new Object();
              totalObject.date = 'Total';
              totalObject.totalClickCount = this.summariesMap.totalClickCount;
              totalObject.affConversionCount = this.summariesMap.affConversionCount;
              totalObject.clickCount = this.summariesMap.clickCount;
              totalObject.lastClickCount = this.summariesMap.lastClickCount;
              totalObject.impressionCount = this.summariesMap.impressionCount;
              totalObject.conversionCount = this.summariesMap.conversionCount;
              totalObject.lastConversionCount = this.summariesMap.lastConversionCount;
              totalObject.totalPayout = this.summariesMap.totalPayout;
              totalObject.lastTotalPayout = this.summariesMap.lastTotalPayout;
              totalObject.totalAdjustPayout = this.summariesMap.totalAdjustPayout;
              totalObject.lastTotalAdjustPayout = this.summariesMap.lastTotalAdjustPayout;
              totalObject.totalRevenue = this.summariesMap.totalRevenue;
              totalObject.lastTotalRevenue = this.summariesMap.lastTotalRevenue;
              totalObject.clickOfferCount = this.summariesMap.clickOfferCount;
              totalObject.lastClickOfferCount = this.summariesMap.lastClickOfferCount;
              totalObject.conversionOfferCount = this.summariesMap.conversionOfferCount;
              totalObject.lastConversionOfferCount = this.summariesMap.lastConversionOfferCount;
              totalObject.ruleId = this.summariesMap.ruleId;
              totalObject.subCount = this.summariesMap.subCount;
              totalObject.subChangeCount = this.summariesMap.subChangeCount;
              this.hotReportList.push(totalObject);
            }
          } else {
            this.hotReportList = [];
            this.summariesMap = {};
            this.total = null;
            this.loading.search = false;
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading.search = false;
          this.$message.error(error);
        });
    },
    exportExcelFileClick() {
      if (this.sourceIdArray != [] && this.sourceIdArray.length > 0) {
        this.commonReport.sourceIds = this.sourceIdArray;
      }

      if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
        this.commonReport.affiliateIds = this.affiliateIdArray;
      }

      let param = {};
      let columns = new Array();

      if (
        this.affiliateChecked == true &&
        columns.indexOf('affiliate_id') == -1 &&
        columns.indexOf('company') == -1
      ) {
        columns.push('affiliate_id');
        columns.push('company');
      } else if (
        this.affiliateChecked == false &&
        columns.indexOf('affiliate_id') !== -1 &&
        columns.indexOf('company') !== -1
      ) {
        const affiliateIndex = columns.findIndex((item) => {
          if (item == 'affiliate_id') {
            return true;
          }
        });
        columns.splice(affiliateIndex, 1);

        const companyIndex = columns.findIndex((item) => {
          if (item == 'company') {
            return true;
          }
        });
        columns.splice(companyIndex, 1);
      }

      if (
        this.sourceChecked == true &&
        columns.indexOf('source_id') == -1 &&
        columns.indexOf('source_name') == -1
      ) {
        columns.push('source_id');
        columns.push('source_name');
      } else if (
        this.sourceChecked == false &&
        columns.indexOf('source_id') !== -1 &&
        columns.indexOf('source_name') !== -1
      ) {
        const sourceIdIndex = columns.findIndex((item) => {
          if (item == 'source_id') {
            return true;
          }
        });
        columns.splice(sourceIdIndex, 1);

        const sourceNameIndex = columns.findIndex((item) => {
          if (item == 'source_name') {
            return true;
          }
        });
        columns.splice(sourceNameIndex, 1);
      }

      if (this.offerChecked == true && columns.indexOf('offer_id') == -1) {
        columns.push('offer_id');
      } else if (this.offerChecked == false && columns.indexOf('offer_id') !== -1) {
        const offerIdIndex = columns.findIndex((item) => {
          if (item == 'offer_id') {
            return true;
          }
        });
        columns.splice(offerIdIndex, 1);
      }

      if (this.countriesChecked == true && columns.indexOf('country') == -1) {
        columns.push('country');
      } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
        const countryIndex = columns.findIndex((item) => {
          if (item == 'country') {
            return true;
          }
        });
        columns.splice(countryIndex, 1);
      }

      if (this.packageChecked == true && columns.indexOf('prod') == -1) {
        columns.push('prod');
      } else if (this.packageChecked == false && columns.indexOf('prod') !== -1) {
        const prodIndex = columns.findIndex((item) => {
          if (item == 'prod') {
            return true;
          }
        });
        columns.splice(prodIndex, 1);
      }

      if (this.affSubsChecked == true && columns.indexOf('aff_sub') == -1) {
        columns.push('aff_sub');
      } else if (this.affSubsChecked == false && columns.indexOf('aff_sub') !== -1) {
        const affSubIndex = columns.findIndex((item) => {
          if (item == 'aff_sub') {
            return true;
          }
        });
        columns.splice(affSubIndex, 1);
      }
      if (this.subRuleIdChecked == true && columns.indexOf('rule_id') == -1) {
        columns.push('rule_id');
      } else if (this.subRuleIdChecked == false && columns.indexOf('rule_id') !== -1) {
        const ruleIdIndex = columns.findIndex((item) => {
          if (item == 'rule_id') {
            return true;
          }
        });
        columns.splice(ruleIdIndex, 1);
      }
      if (this.sourceManagersChecked == true && columns.indexOf('source_manager') == -1) {
        columns.push('source_manager');
      } else if (this.sourceManagersChecked == false && columns.indexOf('source_manager') !== -1) {
        const sourceManagersIndex = columns.findIndex((item) => {
          if (item == 'source_manager') {
            return true;
          }
        });
        columns.splice(sourceManagersIndex, 1);
      }
      if (this.pidChecked == true && columns.indexOf('pid') == -1) {
        columns.push('pid');
      } else if (this.pidChecked == false && columns.indexOf('pid') !== -1) {
        const pidIndex = columns.findIndex((item) => {
          if (item == 'pid') {
            return true;
          }
        });
        columns.splice(pidIndex, 1);
      }
      if (this.offerLinkTypeChecked == true && columns.indexOf('offer_type') == -1) {
        columns.push('offer_type');
      } else if (this.offerLinkTypeChecked == false && columns.indexOf('offer_type') !== -1) {
        const offerLinkTypeIndex = columns.findIndex((item) => {
          if (item == 'offer_type') {
            return true;
          }
        });
        columns.splice(offerLinkTypeIndex, 1);
      }

      // if (this.monthChecked == true && columns.indexOf('month') == -1) {
      //   columns.push('month');
      // } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
      //   const monthIndex = columns.findIndex((item) => {
      //     if (item == 'month') {
      //       return true;
      //     }
      //   });
      //   columns.splice(monthIndex, 1);
      // }

      // if (this.dateChecked == true && columns.indexOf('date') == -1) {
      //   columns.push('date');
      // } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
      //   const dateIndex = columns.findIndex((item) => {
      //     if (item == 'date') {
      //       return true;
      //     }
      //   });
      //   columns.splice(dateIndex, 1);
      // }

      // if (this.hourChecked == true && columns.indexOf('hour') == -1) {
      //   columns.push('hour');
      // } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
      //   const hourIndex = columns.findIndex((item) => {
      //     if (item == 'hour') {
      //       return true;
      //     }
      //   });
      //   columns.splice(hourIndex, 1);
      // }
      if (this.startToEndDate !== null && this.startToEndDate.length === 2) {
        let startDate = this.startToEndDate[0];
        let endDate = this.startToEndDate[1];
        param.fromDate = startDate;
        param.toDate = endDate;
      }
      // if (this.commonReport.timezone != null) {
      //   param['timezone'] = this.commonReport.timezone;
      // }

      if (this.commonReport.sourceIds != null && this.commonReport.sourceIds.length > 0) {
        param['sourceIds'] = this.commonReport.sourceIds + '';
      }
      if (this.commonReport.affiliateIds != null) {
        param['affiliateIds'] = this.commonReport.affiliateIds + '';
      }
      if (this.commonReport.affSubs != null) {
        param['affSubs'] = this.commonReport.affSubs;
      }
      if (this.commonReport.offerIds != null) {
        param['offerIds'] = this.commonReport.offerIds;
      }
      if (this.commonReport.countries != null) {
        param['countries'] = this.commonReport.countries;
      }
      if (this.commonReport.pkgs != null) {
        param['pkgs'] = this.commonReport.pkgs;
      }
      if (columns != null) {
        param['columns'] = columns.join(',');
      }
      if (this.commonReport.platform != null && this.commonReport.platform != '') {
        param['platform'] = this.commonReport.platform;
      }
      if (this.commonReport.ruleIds != null && this.commonReport.ruleIds.length > 0) {
        param['ruleIds'] = this.commonReport.ruleIds.toString();
      }
      if (this.commonReport.sourceManagers.length > 0) {
        param['sourceManagers'] = this.commonReport.sourceManagers.toString();
      }
      if (this.commonReport.pids?.length > 0) {
        param['pids'] = this.commonReport.pids?.toString();
      }
      if (this.commonReport.offerTypes) {
        param['offerTypes'] = this.commonReport.offerTypes?.toString();
      }
      param = filterObject(param);
      let strParam = qs.stringify(param);

      window.open(`${api.DOWNLOAD_HOT_REPORT}?${strParam}`, '_blank');
    },
    handleSizeChange(size) {
      this.pageParam.pageSize = size;
      this.handleCurrentChange(1);
    },
    handleCurrentChange(currentPage) {
      this.pageParam.page = currentPage;
      this.commonReportApi();
    },
    affiliatetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/affiliate/detail',
        query: {
          affiliateId: row.affiliateId,
        },
      });
      window.open(href, '_blank');
    },
    sourcedetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/source/detail',
        query: {
          sourceId: row.sourceId,
        },
      });
      window.open(href, '_blank');
    },
    offerdetailClick(row) {
      const { href } = this.$router.resolve({
        path: '/offer/detail',
        query: {
          offerId: row.offerId,
        },
      });
      window.open(href, '_blank');
    },
    filterNull(val) {
      return val == null ? 0 : val;
    },
    showReportComparingItem(cur, last) {
      const pecent =
        cur == last
          ? '--'
          : 0 == last
          ? '100%'
          : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
      const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
      let result = cur;
      if (last != 0) {
        result += '<span style="color:' + color + '">(' + last + ', ' + pecent + ')</span>';
      }
      return result;
    },
    showReportComparingCrItem(curClicks, lastClicks, curConvs, lastConvs) {
      const cur = new Number((curConvs / (0 == curClicks ? 1 : curClicks)) * 100).toFixed(2);

      let result = cur + '%';
      if (lastClicks != 0) {
        const last = new Number((lastConvs / (0 == lastClicks ? 1 : lastClicks)) * 100).toFixed(2);
        const pecent =
          cur == last
            ? '--'
            : 0 == last
            ? '100%'
            : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
        const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
        result += "<span style='color:" + color + "'>(" + last + '%, ' + pecent + ')</span>';
      }
      return result;
    },
    showReportComparingRpcItem(curClicks, lastClicks, curPayout, lastPayout) {
      const cur = new Number(curPayout / (0 == curClicks ? 1 : curClicks)).toFixed(5);
      const last = new Number(lastPayout / (0 == lastClicks ? 1 : lastClicks)).toFixed(5);
      const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
      let result = cur;
      if (lastClicks != 0) {
        const pecent =
          cur == last
            ? '--'
            : 0 == last
            ? '100%'
            : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
        result += "<span style='color:" + color + "'>(" + last + '%, ' + pecent + ')</span>';
      }
      return result;
    },
    // 排序监听
    sortChange(column) {
      console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.commonReport.sorting = column.prop;
        this.commonReport.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.commonReport.sorting = '';
        this.commonReport.sortType = '';
      }
      this.searchListClick(1);
    },
    // sub rule
    subRuleList() {
      getReportSubruleList().then((res) => {
        console.log(res);
        this.subRule = res.result;
      });
    },
  },
};
