<template>
  <div class="commonReport">
    <!-- <div class="main-Title bgff"><h2>Hot Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form v-model="commonReport" size="mini" label-width="130px">
          <el-row>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Offer(s)" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="commonReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Affiliate(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <group-select
                      v-model="commonReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      class="w100"
                      size="mini"
                    />
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Aff_sub(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="commonReport.affSubs"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Sub_rule_id(s)" label-width="100px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="commonReport.ruleIds"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in subRule"
                        :key="item.ruleId"
                        :label="item.ruleName"
                        :value="item.ruleId"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="subRuleIdChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Countries" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input v-model="commonReport.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Package" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-input
                      v-model="commonReport.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Platform" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select v-model="commonReport.platform" class="w100" placeholder="请选择">
                      <el-option
                        v-for="item in platformOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="platformChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="sourceManagers(s)" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="commonReport.sourceManagers"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in sourceManagers"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="sourceManagersChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="pid(s)" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="commonReport.pids"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="pidChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Offer_link_type" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="commonReport.offerTypes"
                      multiple
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option label="rt" value="rt"></el-option>
                      <el-option label="ua" value="ua"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox v-model="offerLinkTypeChecked"></el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Date" label-width="55px" class="mb5">
                <el-date-picker
                  v-model="startToEndDate"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  type="daterange"
                  class="w100"
                  align="right"
                  unlink-panels
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
              <el-form-item label="Group by" label-width="90px" class="mb5">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="eventName" label-width="80px" class="mb0">
                      <el-checkbox v-model="eventNameChecked"></el-checkbox>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-button
                type="primary"
                size="mini"
                :loading="loading.search"
                @click="searchListClick(1)"
                >In Browser</el-button
              >
              <el-button type="primary" size="mini" @click="exportExcelFileClick"
                >Download Excel</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          stripe
          border
          :data="hotReportList"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
          highlight-current-row
          size="mini"
        >
          <el-table-column
            label="Date"
            width="100"
            prop="date"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            key="date"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          ></el-table-column>
          <el-table-column
            label="Affiliate ID"
            width="100"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
              <el-tag
                v-if="scope.row.affiliateTrafficTag"
                :color="scope.row.affColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affiliateTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Affiliate Name"
            width="140"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            align="center"
            key="affiliateName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Source ID" key="sourceId" v-if="sourceChecked" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.sourceId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            width="110"
            prop="sourceName"
            v-if="sourceChecked"
            align="center"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Prod Name"
            prop="prod"
            width="100"
            key="pkgs"
            v-if="packageChecked"
            align="center"
          >
          </el-table-column>
          <el-table-column
            width="100"
            label="Aff Sub"
            v-if="affSubsChecked"
            align="center"
            key="affSub"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.subpub1">
                <el-popover placement="left" width="200" trigger="hover">
                  <span>FirstLevelSub:</span>
                  <br />
                  <span v-for="(item, index) in scope.row.subpub1.split('-')" :key="index">{{
                    item
                  }}</span>
                </el-popover>
              </template>
              <span>{{ scope.row.affSub }}</span>
              <el-tag
                v-if="scope.row.affSubTrafficTag && scope.row.affSub"
                :color="scope.row.affSubColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affSubTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column width="100" label="ruleId" v-if="subRuleIdChecked" align="center">
            <template slot-scope="scope">
              {{ scope.row.ruleId }}
            </template>
          </el-table-column>

          <el-table-column width="100" label="subCount" v-if="subRuleIdChecked" align="center">
            <template slot-scope="scope">
              {{ scope.row.subCount }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="sourceManager"
            v-if="sourceManagersChecked"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.sourceManager }}
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            label="subChangeCount"
            v-if="subRuleIdChecked"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.subChangeCount }}
            </template>
          </el-table-column>
          <el-table-column
            label="Offer ID"
            width="100"
            v-if="offerChecked"
            key="offerId"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer Name" width="100" v-if="offerChecked" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Status"
            width="110"
            prop="status"
            v-if="offerChecked"
            align="center"
            key="status"
          ></el-table-column>
          <el-table-column
            label="Country"
            width="100"
            prop="country"
            v-if="countriesChecked"
            align="center"
            key="country"
          ></el-table-column>
          <el-table-column
            label="eventName"
            width="100"
            prop="eventName"
            v-if="eventNameChecked"
            align="center"
            key="eventName"
          ></el-table-column>
          <el-table-column
            label="pid"
            width="100"
            prop="pid"
            v-if="pidChecked"
            align="center"
            key="pid"
          ></el-table-column>
          <el-table-column
            label="Offer Link Type"
            width="100"
            prop="offerType"
            v-if="offerLinkTypeChecked"
            align="center"
            key="offerType"
          ></el-table-column>
          <el-table-column
            label="Total Clicks"
            prop="totalClicks"
            width="100"
            key="totalClicks"
            show-overflow-tooltip
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.totalClickCount),
                    filterNull(scope.row.lastTotalClickCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>

          <el-table-column
            label="Clicks"
            prop="clicks"
            width="100"
            align="center"
            key="clicks"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="ImpressionCount"
            prop="impressionCount"
            align="center"
            sortable="custom"
            width="90px"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span>Impression<br />Count</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Supplement Clicks"
            prop="supplementClicks"
            width="100"
            align="center"
          ></el-table-column> -->
          <el-table-column
            label="Conversions"
            prop="conversions"
            width="130"
            align="center"
            key="Conversions"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.conversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="Supplement Conversions"
            prop="supplementConversions"
            width="130"
            align="center"
          ></el-table-column> -->
          <el-table-column
            label="Revenue"
            prop="revenue"
            width="130"
            align="center"
            key="Revenue"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-text="scope.row.totalRevenue"></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Payout"
            prop="payout"
            width="100"
            align="center"
            key="payout"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.totalAdjustPayout),
                    new Number(filterNull(scope.row.lastTotalAdjustPayout)).toFixed(2)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="CR" width="100" align="center" key="cr" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount),
                    filterNull(scope.row.conversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="RPC" width="100" align="center" key="rpc" show-overflow-tooltip>
            <template slot-scope="scope">
              <!-- <span
	            v-html="
	              showReportComparingRpcItem(
	                filterNull(scope.row.clickCount),
	                filterNull(scope.row.lastClickCount),
	                filterNull(scope.row.conversionCount),
	                filterNull(scope.row.lastConversionCount)
	              )
	            "
	          ></span> -->
              <span>
                {{
                  scope.row.conversionCount == 0
                    ? '0.00'
                    : (scope.row.totalRevenue / scope.row.conversionCount).toFixed(2)
                }}
                %
              </span>
            </template>
          </el-table-column>

          <el-table-column
            label="Clicked Offer Count"
            prop="clickOffer"
            min-width="100"
            show-overflow-tooltip
            align="center"
            v-if="!offerChecked"
            key="clickedOfferCount"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header"> Clicked Offer<br />Count </template>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.clickOfferCount),
                    filterNull(scope.row.lastClickOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Converted Offer Count"
            prop="conversionOffer"
            min-width="140"
            align="center"
            show-overflow-tooltip
            v-if="!offerChecked"
            key="convertedOfferCount"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header"> Converted Offer<br />Count </template>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.conversionOfferCount),
                    filterNull(scope.row.lastConversionOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="OUR(Offer Utilization Ratio)"
            width="220"
            align="center"
            v-if="!offerChecked"
            show-overflow-tooltip
            key="our"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.clickOfferCount),
                    filterNull(scope.row.lastClickOfferCount),
                    filterNull(scope.row.conversionOfferCount),
                    filterNull(scope.row.lastConversionOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Platform"
            prop="platform"
            width="100"
            align="center"
            v-if="platformChecked"
            key="platform"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>
<script>
  import HotReportCon from '../controllers/report/hotReport';
  export default {
    name: 'HotReport',
    ...HotReportCon,
  };
</script>
<style lang="scss" scoped></style>
<style lang="scss"></style>
